<template>

<div> 
    <p style="text-align: left; font-weight: bold; font-size:16px; margin-bottom:15px">Markeplace de NFTs</p>

            <b-row v-if="loading">

            <b-col cols="6" sm="6" md="4" lg="4" xl="6" >

            <b-skeleton width="100%"  height="150px"></b-skeleton>
                <b-skeleton width="30%"  height="10px"></b-skeleton>
                <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                    </b-col>

                    <b-col cols="6" sm="6" md="4" lg="4" xl="6" >

            <b-skeleton width="100%"  height="150px"></b-skeleton>
            <b-skeleton width="30%"  height="10px"></b-skeleton>
            <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



            </b-col>

            <b-col cols="6" sm="6" md="4" lg="4" xl="6" >

            <b-skeleton width="100%"  height="150px"></b-skeleton>
            <b-skeleton width="30%"  height="10px"></b-skeleton>
            <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



            </b-col>

            <b-col cols="6" sm="6" md="4" lg="4" xl="6" >

            <b-skeleton width="100%"  height="150px"></b-skeleton>
            <b-skeleton width="30%"  height="10px"></b-skeleton>
            <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



            </b-col>

                    </b-row>

                    <b-row v-else-if="hasDatos"> 


            <b-col v-for="item in nfts" :key="item.id"  cols="12" sm="6" md="4" lg="6" xl="4" style="margin-bottom:15px">
                <b-img-lazy style="  height: 350px;width: 100% !important; object-fit: cover;" v-bind="mainProps" :src="item.url" :alt="item.name"></b-img-lazy>
                    <b-row class="body_card" style="padding:10px 5px">

                        <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:15px">
                            <p style="font-size:18px; font-weight: bold;"> {{ item.name }}</p>


                            </b-col>


                        <b-col  cols="6" sm="6" md="6" lg="6" xl="6" style="margin-bottom:10px">

                 
                    <p style="font-size:12px;margin-bottom:5px ">  Tipo de archivo: <b>{{ item.type }}</b></p>

                    <p v-if="item.type  != 'Imagen'" style="font-size:12px; ">  Duración: <b>{{ item.duracion }}</b></p>
            
            
                    </b-col> 

                    <b-col  cols="6" sm="6" md="6" lg="6" xl="6" style="margin-bottom:10px">

                        <b-button
                                        
                                        variant="outline-secondary"
                                        size="md"
                                        block
                                        @click="descargarNft(item.id, item.precioDescargarNft)"
                                    >
                        
                                    Descargar
                        
                                    </b-button>


                        </b-col>

                    <b-col  cols="6" sm="6" md="6" lg="6" xl="6">
                        <p style="font-size:12px; margin:0px;">  Precio</p>
                        <p style="font-size:18px; font-weight: bold;">  {{ item.price }} USDT</p>

                    </b-col>

                    <b-col  cols="6" sm="6" md="6" lg="6" xl="6">
                        <b-button
                                        
                                        variant="primary"
                                        size="md"
                                        block
                                        @click="buyNft(item.id)"
                                    >
                        
                                    Comprar NFT
                        
                                    </b-button>


                    </b-col>




                    </b-row>


                                    

                </b-col>



            </b-row>

            <b-row v-else> 

            <p>No existen NFTS</p>


            </b-row>
</div>

    </template>
    
    <script>
    import {
      BButton, BRow, BCol, BCard, BSkeleton,BImgLazy
    } from 'bootstrap-vue'
    import 'firebase/compat/storage'
    
    export default {
      components: {
        BButton,
        BSkeleton,
        BRow,
        BCol,
        BCard,
        BImgLazy
      },
      props: ['userId','tokenAuth'],
      data() {
    
  
    
        return {
            loading:true,
            nfts:[],
            hasDatos:false,
            mainProps: {
                      center: true,
                      fluidGrow: true,
                      blank: true,
                      blankColor: '#bbb',
                      width: 200,
                      height: 100,

                    },
    
        }
      },
      computed: {
    
      },
      watch: {
    
      },
      mounted() {
   
        
        this.getNfts();

        
        this.$eventBus.$on('reiniciarNfts', () => {
            this.getNfts();

            });


    
    
      },
      methods: {




       async descargarNft(id, precioDescargarNft){

        
    
        this.$swal({
                title: "¿Deseas descargar este contenido?",
                text: "El precio para descargarlo es de $"+precioDescargarNft,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: "Pagar",
                cancelButtonText: 'Cancelar',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {

                return this.$httpsCryptos.post('/main/downloadNft/', { tokenAuth: this.tokenAuth, userId: this.userId, id:id }).then(response => response.data).catch(error => {
                    this.$swal.showValidationMessage(
                    error,
                    )
                })
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
                }).then(result => {

                this.$swal.close()

                if(result.isConfirmed){


                if (result.value.code == 200) {

                    let url=result.value.url;

          
                    let filename=result.value.nameNft+"."+result.value.extension;

                    let xhr = new XMLHttpRequest();
                        xhr.open("GET", url, true);
                        xhr.responseType = "blob";
                        xhr.onload = function(e) {
                            if (this.status == 200) {
                            const blob = this.response;
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            const blobUrl = window.URL.createObjectURL(blob);
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                            setTimeout(() => {
                                window.URL.revokeObjectURL(blobUrl);
                                document.body.removeChild(a);
                            }, 0);
                            }
                        };
                        xhr.send();
                    
                            this.$toast.success(result.value.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            });





                        } else {
                            this.$toast.error(result.value.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })

                            if (result.data.code == 401) {
                            
                            localStorage.removeItem('userData')


                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            }
                        }

                }





                })

                



        },

        buyNft(id){
    
                this.$swal({
                title: "¿Estas seguro de comprar este NFT?",
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: "Comprar",
                cancelButtonText: 'Cancelar',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {

                return this.$httpsCryptos.post('/main/buyNft/', { tokenAuth: this.tokenAuth, userId: this.userId, id:id }).then(response => response.data).catch(error => {
                    this.$swal.showValidationMessage(
                    error,
                    )
                })
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
                }).then(result => {

                this.$swal.close()

                if(result.isConfirmed){


                if (result.value.code == 200) {

                
                    
                            this.$toast.success(result.value.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            });





                        } else {
                            this.$toast.error(result.value.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })

                            if (result.data.code == 401) {
                            
                            localStorage.removeItem('userData')


                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            }
                        }

                }





                })

                


            },

                getNfts(){
                    this.loading = true;


                        this.$httpsCryptos.post('/main/getNfts/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {

                    
                      
                        if (response.data.code == 200) {
                        this.loading = false;

                        this.nfts=response.data.nfts;

                        this.hasDatos=true;

                

                        } else {

                    


                        if (response.data.code == 401) {

                            this.$toast.error(response.data.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        })
                        
                        localStorage.removeItem('userData')


                        // Redirect to login page
                        this.$router.push({ name: 'auth-login' })
                        } else {

                            if (response.data.code == 404) {
                                this.loading = false;
                                this.hasDatos=false;
                            }else{
                                this.getNfts();
                            }
                    
                        }
                        }
                        }).catch(error => {
                        this.getNfts();
                        })
                        },
            
    
      },
    }
    </script>
    
    <style lang="scss">
    
    
    </style>