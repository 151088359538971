<template>

    <b-modal
          ref="modalNFT"
          centered
          hide-footer
          title="Crear NFT con valor inicial"
          size="sm"
          @hidden="closeModalBuy"
        >
          <div class="d-block text-center">
    
            <b-form
              autocomplete="off"
              @submit.prevent="onSubmit"
            >

            <b-row v-if="editarVideo">


                        
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              
            >

              <video :class="this.isLandscape ? 'heightLandscape' : 'heightPortrait'"   style="width: 100%;" controls id="video">

                                Your browser does not support the video tag.
                              </video>

                              <b-alert
                                  variant="primary"
                                  show
                                  style="margin-top:0px"
                                >
                                  <div class="alert-body">
                                    <p style=" text-align: center;font-size:12px">Selecciona el segundo del video que deseas tener como portada de tu video y luego da click en 'Capturar imagen'</p>
                    
                                  
                                  </div>
                                </b-alert>

                                <b-button
                            
                                variant="primary"
                                block
                                style="    margin-top: 10px;border-radius: 50px;" @click="getThumb"
                              >
                
                              Capturar imagen
                
                              </b-button>
                          


              </b-col>



            </b-row>
    
              <b-row v-else-if="isOk">
    
                  <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-bottom: 15px;padding: 0px;"
                  v-if="isImage"
                >

                <b-img-lazy style="  object-fit: cover;height: 350px;  width: 100% !important;" v-bind="mainProps" :src="imageFinal"></b-img-lazy>

                </b-col>


                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-bottom: 15px;padding: 0px;position:relative;    height: 350px;"
                  v-else-if="isVideo"
                >

          

                <b-img-lazy style="  object-fit: cover;height: 350px;  width: 100% !important;" v-bind="mainProps" :src="thumbnailVideo"></b-img-lazy>

                
                <div  class="playVideoPost" @click="editacionVideo()"> 
                  <feather-icon
                                          icon="PlayIcon"
                                          size="23"

                                          />
                        </div>
                      

                        <div  class="durationVideo"> 
                          {{ durationVideo}}
                        </div>

                        <div @click="editacionVideo()" class="editarVideo"> 
                          <feather-icon
                                          icon="CropIcon"
                                          size="14"

                                          />   Editar
                        </div>

                </b-col>
    
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-bottom: 15px;padding: 0px;"
                  v-else
                >

                <audio id="audio" controls ></audio>
                </b-col>
    
                
    
    
    
                   <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
    
                 <p style="font-size: 14px;text-align: center;margin-bottom: 0;">Asignar valor inicial (*):</p>
    
                </b-col>
    
               
    
    
    
                 <b-col
                 cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                
                    <AutoNumericVue
                    v-model="mountBuy"
                    required
                    type="text" inputmode="decimal"
                    :disabled="isDisabledButtonBuy"
                    :autofocus="false"
                    @input="onChangeMount"
                    @blur="onBlurMount"
                    class="autonumeric_input"
                    :options="json_opciones_buy"
                    :placeholder="'$0.00'"
                ></AutoNumericVue>

             
    
                </b-col>
    
                <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                     
                        >
    
                    <b-alert
                        variant="primary"
                        show
                        style="margin-top:0px"
                      >
                        <div class="alert-body">
                          <p style=" text-align: center;font-size:12px">El monto mínimo es de $50.00 USD y el máximo es de $1.000.000 USD</p>
           
                        
                        </div>
                      </b-alert>
    
                </b-col>
             
    
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-top:15px"
                >
    
                  <b-button
                    id="btn_buy"
                    ref="btn_buy"
                    type="submit"
                    variant="primary"
                    block
                    :disabled="isDisabledButtonBuy"
                  >
    
                    Crear NFT con valor inicial
    
                  </b-button>
    
                </b-col>
    
          
    
           
    
    
              </b-row>

              
              <b-row v-else>
                    
                    <b-col
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
    
                  >

                  <p style="text-align: left; font-size:14px; font-weight: bold;">Subiendo contenido, espere</p>

                  <p style="margin: 0;font-size: 12px;display:flex">{{ uploadValue1.toFixed()+"%" }}
                          <progress
                            style="width:100%;height: 20px;"
                            :value="uploadValue1"
                            max="100"
                          />  </p>

                  </b-col>


                  </b-row>

           
            </b-form>
    
          </div>
    
        </b-modal>
        
             </template>
                
                <script>
                import {
                  BAlert, VBTooltip,BSkeleton,BButton,VBPopover, BRow, BCol, BCard, BFormInput, BForm, BImgLazy
                } from 'bootstrap-vue'
        
                import 'firebase/compat/storage'
        import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
                export default {
                  components: {
                    BButton,
                    AutoNumericVue,
                    BSkeleton,
                    BImgLazy,
                    BFormInput,
                    BRow,
                    BCol,
                    BForm,
                    BAlert,
                    BCard
                  },
                  props: ['userId' ,'base','isChatGpt','extension', 'tokenAuth','descripcionNft'],
                    directives: {
                    'b-popover': VBPopover,
                    'b-tooltip': VBTooltip,
                  },
                  data() {
                    let imagen="";


                    if(this.isChatGpt){
                      imagen= "data:image/png;base64,"+this.base;
                    }else{
                      imagen=this.base;
                    }
            
       
                
                    return {
                      uploadValue1:0,
                        isDisabledButtonBuy:false,
                        mountBuy:"",
                        isOk:false,
                        durationVideo:"",
                        imageFinal:imagen,
                        isImage:true,
                        isLandscape:false,
                        isVideo:false,
                        isAudio:false,
                        editarVideo:false,
                        blobVideo:"",
                        thumbnailVideo:"",
                        priceCripto:0,
                        mainProps: {
                            center: true,
                            fluidGrow: true,
                            blank: true,
                            blankColor: '#bbb',
                            width: 200,
                            height: 100,

                            },
                        json_opciones_buy:{allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 1, maximumValue:1000000, modifyValueOnWheel: false },


                    }
                  },
                  computed: {
                
                  },
                  watch: {
                   
                  },
              async    mounted() {





                this.isOk=true;

                    
                    if(this.extension.includes("image")){

                      this.isImage=true;
                      this.isVideo=false;
                      this.isAudio=false;

                    }else{
                      if(this.extension.includes("video")){
                        var blob = window.URL || window.webkitURL;
                        this.blobVideo = blob.createObjectURL(this.base);
              
              

                        let seconds=  await this.getDurationVideo(this.base); 

         
                        this.durationVideo = new Date(seconds * 1000).toISOString().slice(14, 19);


                        this.thumbnailVideo =  await this.generateVideoThumbnail(this.base);
            

                        this.isImage=false;
                      this.isVideo=true;
                      this.isAudio=false;

                      }else{

            

                        this.thumbnailVideo =  "https://firebasestorage.googleapis.com/v0/b/eqcoop-app.appspot.com/o/music-collection-svgrepo-com.svg?alt=media&token=012a83d8-ec72-41da-8f06-39251805a505";


                      
                            this.isImage=false;
                          this.isVideo=false;
                          this.isAudio=true;
                          var blob = window.URL || window.webkitURL;
                        let  fileURL = blob.createObjectURL(this.base);
                          setTimeout(() =>      document.getElementById('audio').src = fileURL, 100);

                      




                      }
                    }
               
             
                    this.$refs.modalNFT.show();
    
                
            
                
                  },
                  methods: {

                    async getThumb(){

                        const canvas = document.createElement("canvas");

                        var video = document.getElementById('video');

                        //this.currentTime=video.currentTime;



                        let ctx = canvas.getContext("2d");

                            canvas.width = video.videoWidth;
                            canvas.height = video.videoHeight;


                            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

                          
                          this.thumbnailVideo =  canvas.toDataURL('image/jpeg');





                          this.editarVideo=false;
                          this.isOk=true;




                        },
                    loadVideo(){

                      document.querySelector("video").src = this.blobVideo;

                        var video = document.getElementById('video');
                        this.videoWidth= video.videoWidth;
                        this.videoHeight= video.videoHeight;

                        if (this.videoWidth > this.videoHeight) {
                            this.isLandscape=true;
                        }else{
                          this.isLandscape=false;
                        }

                        },
                    editacionVideo(){

                      this.isOk=false;
                        this.editarVideo=true;


                        setTimeout(() => this.loadVideo(), 100);






                        },

        
                      async getDurationVideo(file)  {
                          return new Promise((resolve, reject) => {
                            let videoElement = document.createElement("video");
                            videoElement.preload = "metadata";

                            videoElement.onloadedmetadata = function() {
                              window.URL.revokeObjectURL(videoElement.src);
                              resolve(videoElement.duration);
                            };
                            var blob = window.URL || window.webkitURL;
                            videoElement.src = blob.createObjectURL(file);
                          });
                        },

    
                    async generateVideoThumbnail(file) {
                    return new Promise((resolve) => {
                      const canvas = document.createElement("canvas");
                      const video = document.createElement("video");

                      // this is important
                      video.autoplay = true;
                      video.muted = true;
                      var blob = window.URL || window.webkitURL;
                      video.src = blob.createObjectURL(file);

                      video.onloadeddata = () => {
                        let ctx = canvas.getContext("2d");

                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;

                        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                        video.pause();
                        return resolve(canvas.toDataURL("image/png"));
                      };
                    });
                  },
    
                     onChangeMount(cantidad){
    
                            
    
                        if(cantidad == null || cantidad == "null" || this.mountBuy ==""){
    
    
                   
                            this.isDisabledButtonBuy=false;
    
                        }else{
    
                        if(Number(cantidad) <= Number("0")){
                        this.mountBuy="";
    
    
                            this.isDisabledButtonBuy=false;
    
                        }else{
    
                            this.mountBuy=cantidad;
    
                       
    
                        }
    
                        }
    
    
    
    
                        },
    
                    onBlurMount(){
                            
    
                            if(this.mountBuy == null || this.mountBuy == "null" || this.mountBuy =="" || this.mountBuy == 0){
    
                            this.mountBuy="";
    
                            this.isDisabledButtonBuy=false;
    
                        }else{
    
    
    
               
    
                            if(Number(this.mountBuy) >= Number("50") && Number(this.mountBuy) <= Number("1000000")){
                            
                                this.isDisabledButtonBuy=false;
    

    
                            }else{

                                this.isDisabledButtonBuy=false;
                                this.mountBuy="";
    
                                this.$toast.error("El monto debe ser mayor o igual a $50.00 USD y menor o igual a $1000000 USD", {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                    })
    
                          
    
                            }
    
                        
    
    
                        }
    
                        },
        
    
                    onSubmit(event) {
                        event.preventDefault();


                        if(this.extension.includes("audio")){
                          let seconds= document.getElementById("audio").duration  
                          this.durationVideo = new Date(seconds * 1000).toISOString().slice(14, 19);

                    
                        }

                        this.editarVideo=false;
                        this.isOk=false;
                        this.uploadValue1=0;
                        const d1 = new Date();


                        if(this.isChatGpt){

                          let nameFile= this.userId+"_"+d1.getTime()+"_nft.png";
                                        let dir= 'nft/'+this.userId;

                                        const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).putString(this.imageFinal, 'data_url');


                                        storageRef.on('state_changed', snapshot => {
                                          this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                     
                                        }, error => {
                                        this.$toast.error("Ocurrió un error inesperado: "+error, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                        })

                                        this.$swal.close()
                                      
                                        },
                                        () => {
                                  
                                        storageRef.snapshot.ref.getDownloadURL().then(url => {

                                       
                                          this.$refs.modalNFT.hide();

                                          this.$swal({

                                              html: '<img style="margin-left:10px; width:30px " src="/img/loading.svg" alt="Loading" /> Adquiriendo NFT, espere',
                                              showCloseButton: false,
                                              showCancelButton: false,
                                              focusConfirm: false,
                                              allowEscapeKey: false,
                                              allowOutsideClick: false,
                                              showConfirmButton: false,
                                              position: 'center',
                                              })

                                          

                                          
                                            this.$httpsCryptos.post('/main/buyNft2/', { type:"Imagen",extension:"png",thumbnailVideo:"",durationVideo: "",tokenAuth: this.tokenAuth,userId: this.userId, nftImagen:url, precio: this.mountBuy, descripcion: this.descripcionNft }).then(response => {
                                                this.$swal.close()
                                           
                                                if (response.data.code == 200) {
                                                 
                                        
                                              
                                                     
                                               this.$swal({
                                                 title: '¡Felicidades!',
                                                 text: 'Haz creado tu NFT con valor inicial de $'+this.mountBuy+' USD, ahora tu NFT te generará un 4% mensual de rentabilidad',
                                                 icon: 'success',
                                                 showCancelButton: false,
                                                 confirmButtonText: 'Entendido',
                                                 cancelButtonText: 'No',
                                                 customClass: {
                                                   confirmButton: 'btn btn-primary',
                                                   cancelButton: 'btn btn-outline-secondary ml-1',
                                                 },
                                                 buttonsStyling: false,
                                               });

                                               this.$eventBus.$emit('reiniciarNfts')

                                                      
                                            } else {

                                             
                                                this.$toast.error(response.data.message, {
                                                position: 'top-right',
                                                timeout: 3010,
                                                closeOnClick: true,
                                                pauseOnFocusLoss: false,
                                                pauseOnHover: true,
                                                draggable: true,
                                                draggablePercent: 0.4,
                                                showCloseButtonOnHover: false,
                                                hideProgressBar: true,
                                                closeButton: 'button',
                                                icon: true,
                                                rtl: false,
                                                })

                                                if (response.data.code == 401) {
                                                this.$refs.modalNFT.hide()

                                                
                                                localStorage.removeItem('userData')



                                                // Redirect to login page
                                                this.$router.push({ name: 'auth-login' })
                                                }
                                            }
                                            }).catch(error => {
                                                this.$swal.close()
                                            this.$toast.error("Ocurrió un error inesperado", {
                                                position: 'top-right',
                                                timeout: 3010,
                                                closeOnClick: true,
                                                pauseOnFocusLoss: false,
                                                pauseOnHover: true,
                                                draggable: true,
                                                draggablePercent: 0.4,
                                                showCloseButtonOnHover: false,
                                                hideProgressBar: true,
                                                closeButton: 'button',
                                                icon: true,
                                                rtl: false,
                                            })
                                            })

                                        })
                                        })

                        }else{

                                      
                         if(this.extension.includes("image")){

                        
                          const ext = this.base.split(';')[0].split('/')[1];
                     

                          let nameFile= this.userId+"_"+d1.getTime()+"_nft."+ext;
                                        let dir= 'nft/'+this.userId;

                                        const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).putString(this.imageFinal, 'data_url');


                                        storageRef.on('state_changed', snapshot => {
                                          this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                     
                                        }, error => {
                                        this.$toast.error("Ocurrió un error inesperado: "+error, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                        })

                                        this.$swal.close()
                                      
                                        },
                                        () => {
                                  
                                        storageRef.snapshot.ref.getDownloadURL().then(url => {

                                 

                                          this.$refs.modalNFT.hide();

                                          this.$swal({

                                              html: '<img style="margin-left:10px; width:30px " src="/img/loading.svg" alt="Loading" /> Adquiriendo NFT, espere',
                                              showCloseButton: false,
                                              showCancelButton: false,
                                              focusConfirm: false,
                                              allowEscapeKey: false,
                                              allowOutsideClick: false,
                                              showConfirmButton: false,
                                              position: 'center',
                                              })

        
              
                                            this.$httpsCryptos.post('/main/buyNft2/', {type:"Imagen",extension:ext,thumbnailVideo:"",durationVideo: "", tokenAuth: this.tokenAuth,userId: this.userId, nftImagen:url, precio: this.mountBuy, descripcion: this.descripcionNft }).then(response => {
                                                this.$swal.close()
                                           
                                                if (response.data.code == 200) {
                                                 
                                     
                                                     
                                               this.$swal({
                                                 title: '¡Felicidades!',
                                                 text: 'Haz creado tu NFT con valor inicial de $'+this.mountBuy+' USD, ahora tu NFT te generará un 4% mensual de rentabilidad',
                                                 icon: 'success',
                                                 showCancelButton: false,
                                                 confirmButtonText: 'Entendido',
                                                 cancelButtonText: 'No',
                                                 customClass: {
                                                   confirmButton: 'btn btn-primary',
                                                   cancelButton: 'btn btn-outline-secondary ml-1',
                                                 },
                                                 buttonsStyling: false,
                                               });

                                               this.$eventBus.$emit('reiniciarNfts')

                                               
                                            } else {

                                             
                                                this.$toast.error(response.data.message, {
                                                position: 'top-right',
                                                timeout: 3010,
                                                closeOnClick: true,
                                                pauseOnFocusLoss: false,
                                                pauseOnHover: true,
                                                draggable: true,
                                                draggablePercent: 0.4,
                                                showCloseButtonOnHover: false,
                                                hideProgressBar: true,
                                                closeButton: 'button',
                                                icon: true,
                                                rtl: false,
                                                })

                                                if (response.data.code == 401) {
                                                this.$refs.modalNFT.hide()

                                                
                                                localStorage.removeItem('userData')



                                                // Redirect to login page
                                                this.$router.push({ name: 'auth-login' })
                                                }
                                            }
                                            }).catch(error => {
                                                this.$swal.close()
                                            this.$toast.error("Ocurrió un error inesperado", {
                                                position: 'top-right',
                                                timeout: 3010,
                                                closeOnClick: true,
                                                pauseOnFocusLoss: false,
                                                pauseOnHover: true,
                                                draggable: true,
                                                draggablePercent: 0.4,
                                                showCloseButtonOnHover: false,
                                                hideProgressBar: true,
                                                closeButton: 'button',
                                                icon: true,
                                                rtl: false,
                                            })
                                            })

                                      
                                        })
                                        })


                              }else{
                                if(this.extension.includes("video")){

                                  const ext = this.thumbnailVideo.split(';')[0].split('/')[1];
                     

                                  let nameFile= this.userId+"_"+d1.getTime()+"_poster."+ext;
                                        let dir= 'nft/'+this.userId;

                                        const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).putString(this.thumbnailVideo, 'data_url');


                                        storageRef.on('state_changed', snapshot => {
                                          this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                     
                                        }, error => {
                                        this.$toast.error("Ocurrió un error inesperado: "+error, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                        })

                                        this.$swal.close()
                                      
                                        },
                                        () => {
                                  
                                        storageRef.snapshot.ref.getDownloadURL().then(url => {

                                     

                                          this.uploadValue1=0;


                                
                                          let ext2=   this.base.name.split('.').pop();

                                         

                                            let nameFile= this.userId+"_"+d1.getTime()+"_nft."+ext2;
                                                      let dir= 'nft/'+this.userId;

                                                      const storageRef2 = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(this.base);


                                                      storageRef2.on('state_changed', snapshot => {
                                                        this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                                    
                                                      }, error => {
                                                      this.$toast.error("Ocurrió un error inesperado: "+error, {
                                                          position: 'top-right',
                                                          timeout: 3010,
                                                          closeOnClick: true,
                                                          pauseOnFocusLoss: false,
                                                          pauseOnHover: true,
                                                          draggable: true,
                                                          draggablePercent: 0.4,
                                                          showCloseButtonOnHover: false,
                                                          hideProgressBar: true,
                                                          closeButton: 'button',
                                                          icon: true,
                                                          rtl: false,
                                                      })

                                                      this.$swal.close()
                                                    
                                                      },
                                                      () => {
                                                
                                                      storageRef2.snapshot.ref.getDownloadURL().then(url2 => {

                                                 

                                                        this.$refs.modalNFT.hide();

                                                        this.$swal({

                                                            html: '<img style="margin-left:10px; width:30px " src="/img/loading.svg" alt="Loading" /> Adquiriendo NFT, espere',
                                                            showCloseButton: false,
                                                            showCancelButton: false,
                                                            focusConfirm: false,
                                                            allowEscapeKey: false,
                                                            allowOutsideClick: false,
                                                            showConfirmButton: false,
                                                            position: 'center',
                                                            })

           
                            
                                                          this.$httpsCryptos.post('/main/buyNft2/', {type:"Video",extension:ext2,thumbnailVideo:url,durationVideo: this.durationVideo, tokenAuth: this.tokenAuth,userId: this.userId, nftImagen:url2, precio: this.mountBuy, descripcion: this.descripcionNft }).then(response => {
                                                              this.$swal.close()
                                                          
                                                              if (response.data.code == 200) {
                                                  
                                                      
                                                     
                                                                      this.$swal({
                                                                        title: '¡Felicidades!',
                                                                        text: 'Haz creado tu NFT con valor inicial de $'+this.mountBuy+' USD, ahora tu NFT te generará un 4% mensual de rentabilidad',
                                                                        icon: 'success',
                                                                        showCancelButton: false,
                                                                        confirmButtonText: 'Entendido',
                                                                        cancelButtonText: 'No',
                                                                        customClass: {
                                                                          confirmButton: 'btn btn-primary',
                                                                          cancelButton: 'btn btn-outline-secondary ml-1',
                                                                        },
                                                                        buttonsStyling: false,
                                                                      });

                                                                      this.$eventBus.$emit('reiniciarNfts')


                                                          } else {

                                                            
                                                              this.$toast.error(response.data.message, {
                                                              position: 'top-right',
                                                              timeout: 3010,
                                                              closeOnClick: true,
                                                              pauseOnFocusLoss: false,
                                                              pauseOnHover: true,
                                                              draggable: true,
                                                              draggablePercent: 0.4,
                                                              showCloseButtonOnHover: false,
                                                              hideProgressBar: true,
                                                              closeButton: 'button',
                                                              icon: true,
                                                              rtl: false,
                                                              })

                                                              if (response.data.code == 401) {
                                                              this.$refs.modalNFT.hide()

                                                              
                                                              localStorage.removeItem('userData')



                                                              // Redirect to login page
                                                              this.$router.push({ name: 'auth-login' })
                                                              }
                                                          }
                                                          }).catch(error => {
                                                              this.$swal.close()
                                                          this.$toast.error("Ocurrió un error inesperado", {
                                                              position: 'top-right',
                                                              timeout: 3010,
                                                              closeOnClick: true,
                                                              pauseOnFocusLoss: false,
                                                              pauseOnHover: true,
                                                              draggable: true,
                                                              draggablePercent: 0.4,
                                                              showCloseButtonOnHover: false,
                                                              hideProgressBar: true,
                                                              closeButton: 'button',
                                                              icon: true,
                                                              rtl: false,
                                                          })
                                                          })

                                                    
                                                      })
                                                      })




                                        })
                                        })

                                }else{
   

                                  this.uploadValue1=0;


                                                                  
                                  let ext2=   this.base.name.split('.').pop();



                                    let nameFile= this.userId+"_"+d1.getTime()+"_nft."+ext2;
                                              let dir= 'nft/'+this.userId;

                                              const storageRef2 = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(this.base);


                                              storageRef2.on('state_changed', snapshot => {
                                                this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                            
                                              }, error => {
                                              this.$toast.error("Ocurrió un error inesperado: "+error, {
                                                  position: 'top-right',
                                                  timeout: 3010,
                                                  closeOnClick: true,
                                                  pauseOnFocusLoss: false,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  draggablePercent: 0.4,
                                                  showCloseButtonOnHover: false,
                                                  hideProgressBar: true,
                                                  closeButton: 'button',
                                                  icon: true,
                                                  rtl: false,
                                              })

                                              this.$swal.close()
                                            
                                              },
                                              () => {
                                        
                                              storageRef2.snapshot.ref.getDownloadURL().then(url2 => {

                                  
                                                

                                                this.$refs.modalNFT.hide();

                                                this.$swal({

                                                    html: '<img style="margin-left:10px; width:30px " src="/img/loading.svg" alt="Loading" /> Adquiriendo NFT, espere',
                                                    showCloseButton: false,
                                                    showCancelButton: false,
                                                    focusConfirm: false,
                                                    allowEscapeKey: false,
                                                    allowOutsideClick: false,
                                                    showConfirmButton: false,
                                                    position: 'center',
                                                    })

                                                  this.$httpsCryptos.post('/main/buyNft2/', {type:"Audio",extension:ext2,thumbnailVideo:this.thumbnailVideo,durationVideo: this.durationVideo, tokenAuth: this.tokenAuth,userId: this.userId, nftImagen:url2, precio: this.mountBuy, descripcion: this.descripcionNft }).then(response => {
                                                      this.$swal.close()
                                                  
                                                      if (response.data.code == 200) {
                                                 
                                                     
                                                        this.$swal({
                                                          title: '¡Felicidades!',
                                                          text: 'Haz creado tu NFT con valor inicial de $'+this.mountBuy+' USD, ahora tu NFT te generará un 4% mensual de rentabilidad',
                                                          icon: 'success',
                                                          showCancelButton: false,
                                                          confirmButtonText: 'Entendido',
                                                          cancelButtonText: 'No',
                                                          customClass: {
                                                            confirmButton: 'btn btn-primary',
                                                            cancelButton: 'btn btn-outline-secondary ml-1',
                                                          },
                                                          buttonsStyling: false,
                                                        });

                                                        this.$eventBus.$emit('reiniciarNfts')
  
                                                  
                                                    
                                                      
                                                  } else {

                                                    
                                                      this.$toast.error(response.data.message, {
                                                      position: 'top-right',
                                                      timeout: 3010,
                                                      closeOnClick: true,
                                                      pauseOnFocusLoss: false,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      draggablePercent: 0.4,
                                                      showCloseButtonOnHover: false,
                                                      hideProgressBar: true,
                                                      closeButton: 'button',
                                                      icon: true,
                                                      rtl: false,
                                                      })

                                                      if (response.data.code == 401) {
                                                      this.$refs.modalNFT.hide()

                                                      
                                                      localStorage.removeItem('userData')



                                                      // Redirect to login page
                                                      this.$router.push({ name: 'auth-login' })
                                                      }
                                                  }
                                                  }).catch(error => {
                                                      this.$swal.close()
                                                  this.$toast.error("Ocurrió un error inesperado", {
                                                      position: 'top-right',
                                                      timeout: 3010,
                                                      closeOnClick: true,
                                                      pauseOnFocusLoss: false,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      draggablePercent: 0.4,
                                                      showCloseButtonOnHover: false,
                                                      hideProgressBar: true,
                                                      closeButton: 'button',
                                                      icon: true,
                                                      rtl: false,
                                                  })
                                                  })

                                            
                                              })
                                              })



                                }
                              }
            
                        }

       
    

                            

                                  

                                      
                   
                      
    
                        },
    
            
                    closeModalBuy(){
                    
                      this.$eventBus.$emit('reiniciarModalBuyNFT')
                            
                    },
                  
                                        
                                
                
                
                  },
                }
                </script>
                
                <style lang="scss">
                
            
                
                
                </style>
                