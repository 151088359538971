<template>

<b-row>
  <modal-nft v-if="showModalBuy" :isChatGpt="isChatGpt" :extension="extension" :key="componentKeyModalBuy" :base="base" :user-id="userId" :token-auth="tokenAuth" :descripcion-nft="descripcionNft"  />
  
  <b-col cols="12" sm="12" md="3" lg="4" xl="3" >

    <b-card>
      <b-form
        autocomplete="off"
        @submit.prevent="onSubmit"
      >
      <b-row> 
        <b-col cols="12" sm="12" md="12" lg="12" xl="12" >

          <p style="text-align: center; font-weight: bold; font-size:16px; margin-bottom:15px">Crea tu propio NFT</p>

          <p style="text-align: justify;  font-size:14px; margin-bottom:15px">Utiliza nuestra inteligencia artificial para crear tus propios NFT a partir de tus propias palabras.</p>


          </b-col>

          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >


                 <b-form-textarea
                 ref="mensajeTextArea"
                v-model="description"
                placeholder="Detalla aspectos como tamaño, color, escenarios etc sobre la imagen que deseas crear"
                rows="5"
                no-resize
                required
                :readonly="isDisabled"
                :state="description.length <= maxChar"
                class="char-textarea"
                :class="description.length >= maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-left"
                :class="description.length >= maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
              </small>

          </b-col>

          <b-col
              sm="12"
              md="12"
              lg="12"
              style="margin-top:15px"
              xl="12"
            >

              <b-button
                id="btn_crear"
                ref="btn_crear"
                type="submit"
                block
                variant="primary"
                :disabled="isDisabled"
              >

                Generar imagen con IA

              </b-button>

            </b-col>

      </b-row>
      </b-form>

      </b-card>


      <b-card>

      <b-row> 
        <b-col cols="12" sm="12" md="12" lg="12" xl="12" >

          <p style="text-align: center; font-weight: bold; font-size:16px; margin-bottom:15px">Convierte tu contenido en NFT</p>

          <p style="text-align: center;  font-size:14px; margin-bottom:15px">Sube una imagen, video o audio para convertirlo en NFT</p>

              
          <b-form-file
                        v-model="imagen1"
                        accept="image/jpeg, image/jpg, image/png, video/mp4, video/avi, video/3gp, video/mkv, video/flv, video/mov, audio/*"
                        placeholder="Buscar imagen o suéltelo aquí..."
                        drop-placeholder="Suelte la imagen aqui..."
                        @change="previewImage1"
                      />
        
    


          </b-col>


          </b-row>

          </b-card>
          


    </b-col>

    <b-col cols="12" sm="12" md="9" lg="8" xl="9" >



      <b-tabs
          style="padding: 0px;"
            class="row"
                content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-md-0 sin_padding"
                pills
                
              nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  sin_padding"

                      align="center"
                      >

                        <b-tab>

                          <template #title>
                            <feather-icon
                                                              icon="ShoppingCartIcon"
                                                              size="15"

                                                              class="margin-icon-profile"
                                                              />

                            <span class="titulo_menu">Marketplace </span>
                          </template>

                       
                            <marketplace-nfts :key="componentKeyMarketplaceNft"  :user-id="userId" :token-auth="tokenAuth" />


                        

                      
                        
                    

                      

                        </b-tab>

                        <b-tab @click="ListadoLoad">
                          
                          <template #title>

                            <feather-icon
                                                              icon="CheckCircleIcon"
                                                              size="15"

                                                              class="margin-icon-profile"
                                                              />

                          
                            <span class="titulo_menu" > Mis NFTs comprados </span>
                        </template>
                      
                        <b-card>
                            <listado-nfts v-if="ifListado" :key="componentKeyListado"  :user-id="userId" :token-auth="tokenAuth" />


                          </b-card>

                        </b-tab>

 
            

          </b-tabs>
          

     


</b-col>

      

       
        
        </b-row>

    
         
      
      </template>
      
      <script>
      import {
        BButton, BRow, BCol, BCard, BAvatar, BAlert, BSkeleton, BForm, BFormTextarea, BTabs, BTab, BFormFile
      } from 'bootstrap-vue'
      import ModalNft from './ModalNft.vue'
      import MarketplaceNfts from './MarketplaceNfts.vue'
      import ListadoNfts from './ListadoNfts.vue'
    

      export default {
        components: {
          BButton,
          BFormFile,
          BTabs, BTab,
          BAvatar,
          BRow,
          MarketplaceNfts,
          ModalNft,
          ListadoNfts,
          BCol,
          BCard,
          BForm,
          BAlert,
          BFormTextarea,
          BSkeleton
        },
        props: [],
        data() {
      
            const datos_json = JSON.parse(this.$userGlobal);
   
          return {
            componentKeyMarketplaceNft:100,
            componentKeyListado:200,
            base:"",
            imagen1:[],
            userId:datos_json.userId,
            isChatGpt:false,
            showModalBuy:false,
            componentKeyModalBuy:0,
            isDisabled:false,
             tokenAuth:this.$tokenGlobal,
             ifListado:false,
             description:"",
             descripcionNft:"",
            maxChar: 4000,
            extension:"",
 
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        activated() {

this.$eventBus.$emit('tercerMenu1')
    this.$eventBus.$emit('tercerMenu2')
},
        mounted() {


          this.$eventBus.$on('reiniciarListadoNfts', () => {
      this.componentKeyListado += 1
    });

          this.$eventBus.$on('reiniciarModalBuyNFT', () => {
              this.showModalBuy=false;
              this.componentKeyModalBuy += 1
            });

  
               
            document.title = 'Billeteras de criptomonedas / NFTS'


    
        },
        methods: {

          getBase64(file) {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
              });
            },

          async previewImage1(event) {

                      
            const FileSizeBanner = event.target.files[0].size / 1024 / 1024



            let file=event.target.files[0];

            this.extension=file.type;


            let tamanio= "";
            

            if(this.extension.includes("image")){

              tamanio=10

            }else{
              if(this.extension.includes("video")){

                tamanio=500;

              }else{
                tamanio=250;

              }
            }

            if (FileSizeBanner > Number(tamanio)) {
              this.imagen1 = [];
         
              this.$toast.error('El archivo debe pesar menos de '+tamanio+'mb', {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            }else{

        

           
              if(this.extension.includes("image")){

                this.base= await this.getBase64(file)


                }else{
              
                  this.base= file

                }

              this.isChatGpt=false;
              this.imagen1 = [];
         
              this.descripcionNft="Imagen creada desde EQCoop";

              this.showModalBuy=true;
                   


              


            }
            },

          ListadoLoad(){
            this.ifListado=true;
          },

          onSubmit(event){
      event.preventDefault();

      this.description= this.description.trim();


      if(this.description === "" || Number(this.description.length) <= Number("0")){
         this.description="";
         this.$refs["mensajeTextArea"].focus()
      }else{

        if(Number(this.description.length) > Number(this.maxChar)){

            this.description="";

              this.$toast.error("El texto sobrepasa el límite permitido de 4000 caracteres", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

            }else{

              this.$swal({

                    html:
                                        '<img style="margin-left:10px; width:30px " src="/img/loading.svg" alt="Loading" /> Generando imagen, espere',
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    position: 'center',
                    })




                    this.$https.post('/chat/createImage/', { tokenAuth: this.tokenAuth, userId: this.userId, text: this.description }).then(response => {

                    

                      this.$swal.close()
                    if (response.data.code == 200) {

                      this.base=response.data.base64; 
                      this.descripcionNft=this.description; 

                      this.extension ="image/png";

                      this.description="";
                      this.$refs["mensajeTextArea"].focus()
                      this.isChatGpt=true;
                    
                      this.showModalBuy=true;
                   

                    

                    } else {

                    this.$toast.error(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })


                    if (response.data.code == 401) {
                    
                    localStorage.removeItem('userData')








                    
                    

                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {
                    this.getNotifications();
                    }
                    }
                    }).catch(error => {
                    this.getNotifications();
                    })



            }

      }

   
          },
        
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      